<template>
    <b-card v-if="contractDocs">
        <b-col md="12">
            <div class="d-flex justify-content-between">
                <div>
                    <h3>{{contractDocs.resortsname}} | {{contractDocs.housingnumber}}</h3>
                </div>
                <div>
                    <b-button size="sm" variant="warning" @click="goToHome">
                        <b-icon-arrow-left/>Regresar
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-tabs>
            <b-tab title="Documentos">
                <ListFiles :documents="documents" :isLoadingDocuments="isLoadingDocuments" @delete-file="deleteDocument"/>
            </b-tab>
            <b-tab>
                <template #title >
                    <div v-can="'fivesclub_contracts_show_contracts_action_button_files_add_files'">
                        Subir documentos
                    </div>
                </template>
                <UploadFiles @set-documents="setDocuments" :fileTypes="fileTypes" v-can="'fivesclub_contracts_show_contracts_action_button_files_add_files'"/>
            </b-tab>           
        </b-tabs>
    </b-card>
    <b-card v-else >
        <b-col md="12">
            <div class="d-flex justify-content-between">
                <div><h3>Documentos</h3></div>
                <div>
                <b-button size="sm" variant="warning" @click="goToHome"> <feather-icon icon="ArrowLeftIcon" size="16" /> Regresar</b-button>
                </div>
            </div>       
        </b-col>
        <b-card-body>
            <b-alert variant="danger" show ><div class="alert-body"> Debe de seleccionar un contrado desde el listado de contratos </div></b-alert>            
        </b-card-body>
    </b-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ListFiles from '@/modules/fivesClub/components/contracts/docs/ListFiles'
import UploadFiles from '@/modules/fivesClub/components/contracts/docs/UploadFiles'
import { showAlertMessage } from "@/helpers/helpers"


export default {
    async created() {
        await this.getInitialData()
        // console.log(this.$route.params.idContract )
    },
    destroyed() {
        this.setContractDocs(null)
    },
    components: {
        ListFiles,
        UploadFiles,
    },
    data(){
        return {
          fileTypes: [],
          documents: [],
          isLoadingDocuments: false
        }
    },
    computed: {
        ...mapState('fivesClubContracts', ['contractDocs','requestContracts']),
    }, 
    methods:{
        ...mapActions('fivesClubContracts', ['getDocumentsTypeForContracts','getFilesByContracts','deleteFileInContract','fetchContracts']),
        ...mapMutations('fivesClubContracts', ['setContractDocs','setContracts','setIsLoadingContracts']),                
        async getInitialData(){
            // console.log(this.$route.params.idContract )
            if( this.contractDocs ){
                const { id } = this.contractDocs
                this.isLoadingDocuments = true
                this.documents = await this.getFilesByContracts( { idContract:id } )                        
                this.isLoadingDocuments = false
            }
            this.fileTypes = await this.getDocumentsTypeForContracts()            
        },
        goToHome(){            
            this.setContractDocs(null)
            this.$router.push({ name: 'contracts' })
        },
        setDocuments(documents){
            this.documents = documents
        },      
        async deleteDocument(file){      
            this.isLoadingDocuments = true
            this.setContracts([])
            const { status, message } = await this.deleteFileInContract({idContract: this.contractDocs.id, idDocumet: file.id})
            if( status ){
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                this.documents =  this.documents.filter( doc => doc.id !== file.id)
            }
            this.isLoadingDocuments = false      
            
            if(this.requestContracts){
                this.setIsLoadingContracts(true)
                const contracts = await this.fetchContracts(this.requestContracts)
                this.setContracts(contracts)
                this.setIsLoadingContracts(false)
            }
        }
    }
}
</script>