<template>
    <div>
        <b-table           
            :items="documents"
            :fields="tableColumns"                  
            show-empty
            empty-text="No se tiene archivos para este contrato"
            responsive
            class="position-relative mb-0" 
            v-if="!isLoadingDocuments"     
            >
                <template #cell(fileRoute)="row">
                    <span class="text-nowrap">
                    {{ row.item.fileRoute.split('/').pop()}}
                    </span>
                </template>
                <template #cell(createData)="row">
                    <div v-if="row.item.createUser || row.item.createDate">
                        <span class="text-nowrap" v-if="row.item.createUser">
                            Creado por <br> <strong>{{ row.item.createUser }}</strong>
                        </span> <br>
                        <span class="text-nowrap" v-if="row.item.createDate">
                            Creado el <br> <strong>{{ row.item.createDate }}</strong>
                        </span>
                    </div>
                    <div v-else>
                        N/A
                    </div>
                </template>
                <template #cell(actions)="row">
                    <span class="text-nowrap">
                        <a class="btn btn-sm btn-primary m-half" :href="imgUrl + row.item.fileRoute" target="_blank">
                            <feather-icon icon="ArrowDownCircleIcon" size="16" />
                        </a>
                    </span>
                    <b-button                
                        v-b-tooltip.hover
                        title="Borrar documento"
                        class="btn btn-sm btn-danger ml-1"
                        @click="deleteFile(row.item)"       
                        v-can="'fivesclub_contracts_show_contracts_action_button_files_delete_files'"       
                    >
                        <feather-icon icon="TrashIcon" size="16" />
                    </b-button>
                </template>
        </b-table>  
        <div class="loader-files" v-if="isLoadingDocuments">
            <b-spinner label="Spinning"></b-spinner> 
            <span class="mt-1">Cargando documentos </span>                           
        </div>  
    </div>
</template>

<script>
import { mapState  } from 'vuex'
export default {
    props: {
        documents:{
            type: Array,
            required: true,
        },
        isLoadingDocuments:{
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            imgUrl: process.env.VUE_APP_IMG_SRC_API,                      
            isSavingFile: false,    
            tableColumns: [
                { key: 'name', label: 'Nombre',},
                { key: 'description', label: 'Descripción' },
                { key: 'typename', label: 'Tipo' },
                { key: 'fileRoute', label: 'Archivo'},
                { key: 'createData', label: 'Creación'},
                { key: 'actions' },                     
            ],        
        }
    },
    computed: {
        ...mapState('fivesClubContracts', ['contractDocs']),
    },   
    methods:{
        async deleteFile(file){
             const isConfirmed = await this.confirmDeletFile() 
             if(isConfirmed){                
                this.$emit('delete-file', file)                
             }
        },
        async confirmDeletFile(){
            const { isConfirmed } = await this.$swal.fire({
                icon: 'warning',
                title: `Borrar documento`, 
                text: `¿Estás seguro de borrar este documento?`,
                showDenyButton: true,
                confirmButtonText: `Sí, seguro`,
                denyButtonText: `Cancelar`,
                customClass: {                
                    confirmButton: 'mr-1',        
                }
            })
            return isConfirmed
        }
    }  
}
</script>

<style scoped>
.loader-files{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;    
}
.m-half{
    margin: 0.5rem 1rem
}
</style>